import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

// Config Azure AD app setting to be passed to Msal on creation
//const TenantId = "524fb73d-cb2a-45ef-81e0-873b9a162c50";
const TenantId = "97b3d0e8-20ee-488f-83bc-d62db512f1ca";
//const ClientId = "38597ec5-bca7-477b-b23b-23d4d5ec36e1";
const ClientId = "9bd884c3-e215-4154-8d30-e2d06575d54d";

export const msalConfig: Configuration = {
    auth: {
        clientId: ClientId,
        authority: "https://login.microsoftonline.com/" + TenantId,
        redirectUri: "/",
        postLogoutRedirectUri: "/"        
    }
};

export const PowerBiPermissionScopes: string[] = [
    "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
    "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
    "https://analysis.windows.net/powerbi/api/Report.ReadWrite.All",
    "https://analysis.windows.net/powerbi/api/Group.Read.All",
    "https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All",
    "https://analysis.windows.net/powerbi/api/Content.Create"
  ]

export const PowerBiLoginRequest: RedirectRequest = {
    scopes: PowerBiPermissionScopes
};